import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../../Layout-old/MaxWidth';
import { ButtonLink } from '../Button';
import { theme } from '../../Theme';
import useChildren from '../hooks/useChildren';
import LargeImageItem from '../ContentItems/LargeImageItem';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';

const HeroBlockWrapper = styled(MaxWidth)`
  position: relative;
  background-color: ${theme.colors.white};
`;

const Title = styled('span')`
  font-family: ${theme.fonts.highlight};
  font-size: 2.625rem; // 42px
  line-height: 3.75rem;
  letter-spacing: 0.05em;

  ${theme.below.md} {
    font-size: 2.2rem;
  }
`;

const HeroBlockOverlay = (props) => {
  const { title, buttonLabel, buttonTo } = props;

  const Overlay = styled('div')`
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.7);
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    ${theme.above.lg} {
      min-height: 6.875rem;
    }

    ${theme.below.lg} {
      padding-top: 0.5rem;
      padding-bottom: 1.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
      flex-direction: column;
      > span {
        text-align: center;
      }
    }

    &.hero-overlay-both {
      > span {
        ${theme.above.lg} {
          margin-right: 1.25rem;
        }
        ${theme.below.lg} {
          margin-bottom: 1rem;
        }
      }
    }

    &.hero-overlay-button {
      ${theme.below.lg} {
        padding-top: 1.25rem;
      }
    }
  `;

  if (title && buttonLabel && buttonTo) {
    return (
      <Overlay className="hero-overlay-both">
        <Title>{title}</Title>
        <ButtonLink to={buttonTo}>{buttonLabel}</ButtonLink>
      </Overlay>
    );
  } else if (title) {
    return (
      <Overlay className="hero-overlay-title">
        <Title>{title}</Title>
      </Overlay>
    );
  } else if (buttonLabel && buttonTo) {
    return (
      <Overlay className="hero-overlay-button">
        <ButtonLink to={buttonTo}>{buttonLabel}</ButtonLink>
      </Overlay>
    );
  }

  return null;
};

const HeroBlock = (props) => {
  const allProps = useChildren(props);
  const largeImageItems = allProps[LargeImageItem];

  const largeImageItemProps =
    largeImageItems && largeImageItems.length > 0 && largeImageItems.pop();
  const { image, alt } = largeImageItemProps;

  return (
    <HeroBlockWrapper removeMobilePadding={true} removeDesktopPadding={true}>
      <Above breakpoint="md">
        {(matches) => (
          <Image
            critical={true}
            className="image-holder"
            aspect={matches ? '3:1' : '10:11'}
            sizes={[1, 1680]}
            src={image}
            alt={alt}
            crop={true}
            cover={true}
          />
        )}
      </Above>
      <HeroBlockOverlay
        title={allProps.title}
        buttonLabel={allProps.label}
        buttonTo={allProps.to}
      />
    </HeroBlockWrapper>
  );
};

export default HeroBlock;
