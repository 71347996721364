import React from 'react';
import JetShopImage from '@jetshop/ui/Image';
import { styled } from 'linaria/react';

export const IMAGE_FORMATS = {
  WIDE: '832 x 468 - 16:9',
  SQUARE: '832 x 832 - Square',
  CATEGORY: '832 x 313 - Category',
  ONE_WIDTH: '1680 x 459 - One Width',
};

export const Image = ({ src, alt, imageFormat }) => {
  switch (imageFormat) {
    case IMAGE_FORMATS.ONE_WIDTH:
      return <JetShopImage src={src} alt={alt} aspect="1170:320" sizes={[1 / 2]} />

    case IMAGE_FORMATS.CATEGORY:
      return (
        <JetShopImage
          src={src}
          alt={alt}
          aspect="577:217"
          sizes={[1, 1, 1 / 2, 1 / 2, 1 / 2, 1 / 2]}
        />
      );

    case IMAGE_FORMATS.SQUARE:
      return (
        <JetShopImage
          src={src}
          alt={alt}
          aspect="1:1"
          sizes={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 2]}
        />
      );

    case IMAGE_FORMATS.WIDE:
    default:
      return (
        <JetShopImage
          src={src}
          alt={alt}
          aspect="16:9"
          sizes={[1, 1, 1, 1 / 2]}
        />
      );
  }
};

const ResponsiveImage = styled('img')`
  max-width: 100%;
  height: auto;
`;

export const ImageFull = ({ src, alt }) => {
  return <ResponsiveImage src={src} alt={alt} />;
};
